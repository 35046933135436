

.grade-content-dropdown-second{
    color: #a6a6a6;
    cursor: pointer;
    outline: none;
    font-size: 1rem;
    border: 1px solid #a6a6a6;
    min-height: 2rem;
    min-width: 6rem!important;
    margin-left: 0.5rem;
    .react-select__control{
        background: black;
        outline: none;
        border: none;
        min-height: 1.5rem;
        box-shadow: none;
        .react-select__value-container{
            border: none;
            .react-select__single-value{
                font-size: 1rem;
                color: white;
            }
        }
        .react-select__indicators{
            border: none;
            padding: 0;
            .react-select__indicator-separator{
                display: none;
            }
            .react-select__indicator{
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}
.grade-content-dropdown-third{
    color: #a6a6a6;
    cursor: pointer;
    outline: none;
    font-size: 1rem;
    border: 1px solid #a6a6a6;
    min-height: 2rem;
    min-width: 5rem;
    margin-left: 0.5rem;
    .react-select__control{
        background: black;
        outline: none;
        border: none;
        min-height: 1.5rem;
        box-shadow: none;
        .react-select__value-container{
            border: none;
            .react-select__single-value{
                font-size: 1rem;
                color: white;
            }
        }
        .react-select__indicators{
            border: none;
            padding: 0;
            .react-select__indicator-separator{
                display: none;
            }
            .react-select__indicator{
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}