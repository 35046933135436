.group-container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    .group-sticky{
        width: 100vw;
        min-height: calc(100vh - 8rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        .group-header{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: 0 12rem;
            padding-top: 3rem;
            height: 4rem;
            color: white;
            width: calc(100% - 24rem);
            .group-header-left{
                width: 80%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .group-header-title{
                    font-size: 1.6rem;
                    min-width: 130px;
                }
                .group-header-search{
                    min-height: 2rem;
                    width: 8rem;
                    border: 1px solid #a6a6a6;
                    font-size: 1rem;
                    outline: none;
                    margin-left: 3rem;
                    background: black;
                    color: white;
                    padding: 0 0.5rem;
                }
                .group-header-btn{
                    background: #404040;
                    color: #a8a8a8;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: calc(2rem - 1rem);
                    padding: 0.5rem 1.5rem;
                    margin-left: 0.5rem;
                    cursor: pointer;
                    min-width: 40px;
                    &:hover{
                        color: #777676;
                    }
                }
            }
            .group-header-right{
                width: 20%;
                display: flex;
                justify-content: flex-end;
                .group-new{
                    border: 1px solid #a6a6a6;
                    border-radius: 20px;
                    color: white;
                    font-size: 1.2rem;
                    padding: 0.2rem 1.5rem;
                    text-decoration: none;
                    cursor: pointer;
                    &:hover{
                        color: #777676;
                    }
                }
            }
            
        }
        .group-body{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .group-body-header{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                background: #262626;
                width: 100%;
                height: 3rem;
                .group-body-header-text{
                    color: #a6a6a6;
                    font-size: 1.2rem;
                    display: flex;
                    justify-content: flex-start;
                    width: 20vw;
                    margin: 0 15vw;
                    height: 100%;
                    align-items: center;
                }
            }
            .group-body-content{
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }
}

.groupupdate-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #a6a6a6;
    width: 100vw;
    height: 100vh;
    .groupupdate-sticky{
        height: calc(100% - 8rem);
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .groupupdate-input{
            background: #262626;
            border: none;
            font-size: 1.2rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
        }
        .groupupdate-btns{
            width: calc(100% - 2rem);
            padding: 1rem;
            padding-top: 3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-width: 600px;
            .groupupdate-btn{
                width: 45%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid #807f80;
                cursor: pointer;
                &:hover{
                    color: #777676;
                }
                &:nth-child(1){
                    margin-right: 0.5rem;
                }
                &:nth-child(2){
                    margin-left: 0.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .group-container{
        .group-sticky{
            .group-header{
                padding: 0;
                padding-top: 1rem;
                width: 100%;
                .group-header-left{
                    width: 100%;
                    .group-header-title{
                        display: none;
                    }
                }
                .group-header-right{
                    width: 0;
                    .group-new{
                        position: fixed;
                        bottom: 30px;
                        right: 30px;
                    }
                }
            }
            .group-body{
                .group-body-header{
                    .group-body-header-text{
                        &:nth-child(1){
                            display: flex;
                            justify-content: center;
                            width: 50vw;
                            margin-left: 10vw;
                        }
                        &:nth-child(2){
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .groupupdate-container{
        .groupupdate-sticky{
            width: 100%;
            .groupupdate-input{
                width: 80%;
                min-width: 80%;
            }
            .groupupdate-btns{
                display: flex;
                flex-direction: column;
                .groupupdate-btn{
                    font-size: 1.2rem;
                    &:nth-child(1){
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                    &:nth-child(2){
                        margin-left: 0;
                        margin-bottom: 5rem;
                    }
                }
            }
        }
    }
}