.vid-container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    .vid-sticky{
        width: 100vw;
        min-height: calc(100vh - 8rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        .vid-header{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 12rem;
            padding-top: 3rem;
            height: 4rem;
            color: white;
            width: calc(100% - 24rem);
            .vid-header-left{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .vid-header-title{
                    font-size: 1.6rem;
                    min-width: 130px;
                }
                .mobile-separator{
                    display: flex;
                    flex-direction: row;
                    .test-content-dropdown-third{
                        color: #a6a6a6;
                        cursor: pointer;
                        outline: none;
                        font-size: 1rem;
                        border: 1px solid #a6a6a6;
                        min-height: 2rem;
                        min-width: 5.5rem;
                        margin-left: 2rem;
                        .react-select__control{
                            background: black;
                            outline: none;
                            border: none;
                            min-height: 1.5rem;
                            box-shadow: none;
                            .react-select__value-container{
                                border: none;
                                .react-select__single-value{
                                    font-size: 1rem;
                                    color: white;
                                }
                            }
                            .react-select__indicators{
                                border: none;
                                padding: 0;
                                .react-select__indicator-separator{
                                    display: none;
                                }
                                .react-select__indicator{
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                    .test-content-dropdown-second{
                        color: #a6a6a6;
                        cursor: pointer;
                        outline: none;
                        font-size: 1rem;
                        border: 1px solid #a6a6a6;
                        min-height: 2rem;
                        min-width: 8rem;
                        margin-left: 0.5rem;
                        .react-select__control{
                            background: black;
                            outline: none;
                            border: none;
                            min-height: 1.5rem;
                            box-shadow: none;
                            .react-select__value-container{
                                border: none;
                                .react-select__single-value{
                                    font-size: 1rem;
                                    color: white;
                                }
                            }
                            .react-select__indicators{
                                border: none;
                                padding: 0;
                                .react-select__indicator-separator{
                                    display: none;
                                }
                                .react-select__indicator{
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                    .test-content-dropdown-first{
                        color: #a6a6a6;
                        cursor: pointer;
                        outline: none;
                        font-size: 1rem;
                        border: 1px solid #a6a6a6;
                        min-height: 2rem;
                        min-width: 5.5rem;
                        margin-left: 0.5rem;
                        .react-select__control{
                            background: black;
                            outline: none;
                            border: none;
                            min-height: 1.5rem;
                            box-shadow: none;
                            .react-select__value-container{
                                border: none;
                                .react-select__single-value{
                                    font-size: 1rem;
                                    color: white;
                                }
                            }
                            .react-select__indicators{
                                border: none;
                                padding: 0;
                                .react-select__indicator-separator{
                                    display: none;
                                }
                                .react-select__indicator{
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                    .vid-header-search-btn{
                        background: #404040;
                        color: #a8a8a8;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: calc(2rem - 1rem);
                        padding: 0.5rem 1.5rem;
                        margin-left: 0.5rem;
                        cursor: pointer;
                        min-width: 40px;
                        &:hover{
                            color: #777676;
                        }
                    }
                }
                
            }
            .vid-header-right{
                width: 30%;
                display: flex;
                justify-content: flex-end;
                .vid-register{
                    border: 1px solid #a6a6a6;
                    border-radius: 20px;
                    color: white;
                    font-size: 1.2rem;
                    padding: 0.2rem 1.5rem;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        .vid-body{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .vid-body-header{
                display: flex;
                flex-direction: row;
                align-items: center;
                background: #262626;
                width: 100%;
                height: 3rem;
                .vid-body-header-text{
                    color: #a6a6a6;
                    font-size: 1.2rem;
                    display: flex;
                    justify-content: center;
                    &:nth-child(1){
                        margin-left: 7vw;
                        width: 18vw;
                    }
                    &:nth-child(2){
                        width: 16vw;
                    }
                    &:nth-child(3){
                        width: 12vw;
                    }
                    &:nth-child(4){
                        width: 12vw;
                    }
                    &:nth-child(5){
                        width: 12vw;
                    }
                }
                .mat-body-header-text{
                    color: #a6a6a6;
                    font-size: 1.2rem;
                    display: flex;
                    justify-content: center;
                    &:nth-child(1){
                        margin-left: 10vw;
                        width: 15vw;
                    }
                    &:nth-child(2){
                        width: 15vw;
                    }
                    &:nth-child(3){
                        width: 15vw;
                    }
                    &:nth-child(4){
                        width: 15vw;
                    }
                    &:nth-child(5){
                        width: 12vw;
                    }
                }
            }
            .vid-content-body{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .vid-container{
        overflow-x: hidden;
        .vid-sticky{
            .vid-header{
                width: 100%;
                height: 5.2rem;
                .vid-header-left{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    .vid-header-title{
                        font-size: 1.3rem;
                    }
                    .mobile-separator{
                        margin-top: 1rem;
                        margin-bottom: 0.5rem;
                        .test-content-dropdown-third{
                            min-width: 4rem;
                            margin-left: 1rem;
                        }
                        .test-content-dropdown-second{
                            min-width: 6rem;
                        }
                        .test-content-dropdown-first{
                            min-width: 4rem;
                        }
                        .vid-header-search-btn{
                            margin-right: 1rem;
                        }
                    }
                }
                
            }
            .vid-body{
                margin-bottom: 5rem;
                .vid-body-header{
                    .vid-body-header-text{
                        &:nth-child(1){
                            width: 60vw;
                            font-size: 1rem;
                        }
                        &:nth-child(2){
                            display: none;
                        }
                        &:nth-child(3){
                            display: none;
                        }
                        &:nth-child(4){
                            width: 40vw;
                            font-size: 1rem;
                        }
                        &:nth-child(5){
                            display: none;
                        }
                    }
                    .mat-body-header-text{
                        &:nth-child(1){
                            width: 60vw;
                            font-size: 1rem;
                        }
                        &:nth-child(2){
                            display: none;
                        }
                        &:nth-child(3){
                            display: none;
                        }
                        &:nth-child(4){
                            width: 40vw;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}