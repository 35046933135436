.academy-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .academy-content-container{
        height: calc(100vh - 8rem);
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 5rem;
        .academy-content-test{
            text-decoration: none;
            color: #a6a6a6;
            font-size: 1.5rem;
            width: 270px;
            background: #262626;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 270px;
            margin-right: 0.5rem;
            background: #2d3c20;
            &:hover{
                background: #1d2715;
            }
        }
        .academy-content-student{
            text-decoration: none;
            color: #a6a6a6;
            background: #262626;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 270px;
            font-size: 1.5rem;
            width: 270px;
            margin-left: 0.5rem;
            background: #1d2944;
            &:hover{
                background: #12192b;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .academy-container{
        overflow: hidden;
        
        .academy-content-container{
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            height: calc(100% - 48px);
            .academy-content-test{
                height: 4.5rem;
                width: 700px;
                margin: 0;
                font-size: 1.2rem;
                margin-bottom: 10px;
            }
            .academy-content-student{
                height: 4.5rem;
                width: 700px;
                margin: 0;
                font-size: 1.2rem;
            }
        }
    }
}