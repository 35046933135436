

.home-content-container{
    height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .home-content{
        color: #a6a6a6;
        font-size: 1.5rem;
        margin: 1px 0;
        background: #1f1f1f;
        opacity: 0.8;
        text-decoration: none;
        min-width: 700px;
        height: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(3){
            margin-bottom: 5rem;
        }
        span{
            font-weight: bold;
            color: white;
        }
        &:hover{
            background: #0e0d0d;
        }
    }
}
.admin-btn{
    color: white;
    border: 2px solid #a6a6a6;
    font-weight: 600;
    font-size: 1.5rem;
    position: fixed;
    bottom: 30px;
    right: 30px;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    cursor: pointer;
}
input{
    font-family: "yun";
}

@media screen and (max-width: 1024px) {
    .admin-btn{
        display: none;
    }
    .home-content-container{
        height: calc(100% - 48px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 0;
        .home-content{
            color: #a6a6a6;
            font-size: 1.2rem;
            margin: 1px 0;
            background: #1f1f1f;
            opacity: 0.8;
            text-decoration: none;
            width: 150px;
            height: 4.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            &:nth-child(3){
                margin-bottom: 3rem;
            }
            span{
                font-weight: bold;
                color: white;
            }
            &:hover{
                background: #0e0d0d;
            }
        }
    }
}