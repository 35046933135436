.scoreupdate-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .scoreupdate-title{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        color: white;
        padding: 0 1rem;
        font-size: 1.5rem;
        padding-bottom: 2rem;
    }
    .scoreupdate-sticky{
        width: 50%;
        height: calc(100% - 8rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .scoreupdate-inputs{
            width: 100%;
            display: flex;
            justify-content: center;
            .scoreupdate-input{
                background: #262626;
                border: none;
                font-size: 1.4rem;
                padding: 1rem;
                color: white;
                outline: none;
                width: calc(100% - 2rem);
                min-width: 600px;
                margin-bottom: 0.3rem;
                &::placeholder{
                    font-weight: 400;
                } 
            }
        }
        .scoreupdate-btns{
            width: calc(100% - 2rem);
            padding: 1rem;
            padding-top: 3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-width: 600px;
            .scoreupdate-btn{
                width: 45%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid rgb(128, 127, 128);
                cursor: pointer;
                &:nth-child(1){
                    margin-right: 0.5rem;
                }
                &:nth-child(2){
                    margin-left: 0.5rem;
                }
            }
        }
    }
}