.find-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input{
        padding: 1rem;
        font-size: 1.5rem;
        border: none;
        background: #262626;
        color: white;
        outline: none;
    }
    .find-btn{
        margin-top: 1rem;
        cursor: pointer;
        font-size: 1.2rem;
    }
}