.newgroup-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .newgroup-content-container{
        height: calc(100% - 8rem);
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .newgroup-content-title{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            color: white;
            padding: 0 1rem;
            font-size: 1.5rem;
            padding-bottom: 2rem;
        }
        .newgroup-content-input{
            background: #262626;
            border: none;
            font-size: 1.4rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
            &::placeholder{
                font-weight: 400;
            } 
        }
        .newgroup-content-btn-container{
            width: calc(100% - 2rem);
            padding: 1rem;
            padding-top: 3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-width: 600px;
            .newgroup-content-btn{
                width: 45%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid rgb(128, 127, 128);
                cursor: pointer;
                &:hover{
                    color: #777676;
                }
                &:nth-child(1){
                    margin-right: 0.5rem;
                }
                &:nth-child(2){
                    margin-left: 0.5rem;
                }
            }
        }
    }
}
@media screen and (max-width: 1024px) {
    .newgroup-container{
        overflow: hidden;
        .newgroup-content-container{
            width: 100%;
            .newgroup-content-title{
                margin-left: 4rem;
            }
            .newgroup-content-input{
                min-width: 80%;
                width: 80%;
            }
            .newgroup-content-btn-container{
                display: flex;
                flex-direction: column;
                .newgroup-content-btn{
                    &:nth-child(1){
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                    &:nth-child(2){
                        margin-left: 0;
                        
                    }
                    font-size: 1.2rem;
                    
                }
            }
        }
    }
}