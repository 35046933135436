.grade-container{
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .grade-content-container{
        width: 100%;
        min-height: calc(100% - 8rem);
        display: flex;
        flex-direction: column;
        .grade-content-header-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 12rem;
            padding-top: 3rem;
            height: 4rem;
            color: white;
            width: calc(100% - 24rem);
            .grade-content-header-left{
                width: 80%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .grade-content-title{
                    font-size: 1.6rem;
                }
                .mobile-separator{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 1rem;
                    margin-bottom: 0.5rem;
                    .grade-content-dropdown-first1{
                        color: #a6a6a6;
                        cursor: pointer;
                        outline: none;
                        font-size: 1rem;
                        border: 1px solid #a6a6a6;
                        min-height: 2rem;
                        min-width: 5rem;
                        margin-left: 6rem;
                        
                        .react-select__control{
                            background: black;
                            outline: none;
                            border: none;
                            min-height: 1.5rem;
                            box-shadow: none;
                            .react-select__value-container{
                                border: none;
                                .react-select__single-value{
                                    font-size: 1rem;
                                    color: white;
                                }
                            }
                            .react-select__indicators{
                                border: none;
                                padding: 0;
                                .react-select__indicator-separator{
                                    display: none;
                                }
                                .react-select__indicator{
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                    .grade-content-dropdown-second{
                        color: #a6a6a6;
                        cursor: pointer;
                        outline: none;
                        font-size: 1rem;
                        border: 1px solid #a6a6a6;
                        min-height: 2rem;
                        min-width: 5rem;
                        margin-left: 0.5rem;
                        .react-select__control{
                            background: black;
                            outline: none;
                            border: none;
                            min-height: 1.5rem;
                            box-shadow: none;
                            .react-select__value-container{
                                border: none;
                                .react-select__single-value{
                                    font-size: 1rem;
                                    color: white;
                                }
                            }
                            .react-select__indicators{
                                border: none;
                                padding: 0;
                                .react-select__indicator-separator{
                                    display: none;
                                }
                                .react-select__indicator{
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                    .grade-content-search-btn{
                        background: #404040;
                        color: #a8a8a8;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: calc(2rem - 1rem);
                        padding: 0.5rem 1.5rem;
                        margin-left: 0.5rem;
                        cursor: pointer;
                        min-width: 40px;
                        text-decoration: none;
                        &:hover{
                            color: #777676;
                        }
                    }
                }
            }
            .grade-content-header-right{
                width: 20%;
                display: flex;
                justify-content: flex-end;
                .grade-register{
                    border: 1px solid #a6a6a6;
                    border-radius: 20px;
                    color: white;
                    font-size: 1.2rem;
                    padding: 0.2rem 1.5rem;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        .grade-content-body-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .grade-content-body-header{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                background: #262626;
                width: 100%;
                height: 3rem;
                .grade-content-body-header-text{
                    color: #a6a6a6;
                    font-size: 1.2rem;
                    display: flex;
                    justify-content: center;
                    &:nth-child(1){
                        width: 24vw;
                        margin-left: 4vw;
                    }
                    &:nth-child(2){
                        width: 8vw;
                    }
                    &:nth-child(3){
                        width: 8vw;
                    }
                    &:nth-child(4){
                        width: 8vw;
                    }
                    &:nth-child(5){
                        width: 13vw;
                    }
                }
            }
            .grade-content-body{
                display: flex;
                flex-direction: column;
                width: 100%;
                .gradecontent-container{
                    display: flex;
                    flex-direction: row;
                    height: 4rem;
                    align-items: center;
                    font-size: 1.1rem;
                    &:nth-child(1){
                        padding-top: 1rem;
                    }
                    &:nth-child(2n){
                        background-color: #171717;
                    }
                    .gradecontent-name{
                        width: 24vw;
                        margin-left: 4vw;
                        display: flex;
                        justify-content: center;
                    }
                    .gradecontent-score{
                        width: 8vw;
                        display: flex;
                        justify-content: center;
                    }
                    .gradecontent-percent{
                        width: 8vw;
                        display: flex;
                        justify-content: center;
                    }
                    .gradecontent-rank{
                        width: 8vw;
                        display: flex;
                        justify-content: center;
                    }
                    .gradecontent-rating{
                        width: 13vw;
                        display: flex;
                        justify-content: center;
                    }
                    .gradecontent-table{
                        width: 15vw;
                        display: flex;
                        justify-content: center;
                        .table{
                            background: rgb(46, 60, 32);
                            padding: 0.5rem 2rem;
                            color: white;
                            cursor: pointer;
                            &:hover{
                                color: #777676;
                            }
                        }
                    }
                    .gradecontent-modify-remove{
                        width: 15vw;
                        margin-right: 5vw;
                        display: flex;
                        justify-content: center;
                        .gradecontent-modify{
                            cursor: pointer;
                            &:hover{
                                color: #777676;
                            }
                        }
                        .gradecontent-remove{
                            cursor: pointer;
                            &:hover{
                                color: #777676;
                            }
                        }
                    }
                }
                .grade-content-footer{
                    display: flex;
                    flex-direction: row;
                    margin-left: 10vw;
                    margin-top: 4rem;
                    .grade-footer{
                        font-size: 1.2rem;
                        color: white;
                        padding: 0.7rem 2rem;
                        border: 1px solid #a6a6a6;
                        margin-right: 1rem;
                        text-decoration: none;
                        &:hover{
                            color: #777676;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .grade-container{
        .grade-content-container{
            width: 100%;
            margin-top: 2.5rem;
            .grade-content-header-container{
                padding: 0;
                width: 100%;
                height: 5.2rem;
                .grade-content-header-left{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    .grade-content-title{
                        font-size: 1.3rem;
                    }
                    .mobile-separator{
                        .grade-content-dropdown-first{
                            margin-left: 0!important;
                            min-width: 4rem;
                        }
                        .grade-content-dropdown-first1{
                            margin-left: 0!important;
                        }
                        .group-add{
                            position: absolute;
                            top: 60px;
                            right: 10px;
                            cursor: pointer;
                            background-color: black;
                            border: 1px solid #a6a6a6;
                            border-radius: 20px;
                            color: white;
                            font-size: 1rem;
                            padding: .2rem 1.5rem;
                        }
                    }
                }
                .grade-content-header-right{
                    width: 0;
                }
            }
            .grade-content-body-container{
                .grade-content-body-header{
                    .grade-content-body-header-text{
                        &:nth-child(1){
                            width: 48vw;
                            font-size: 1rem;
                            margin-left: 0;
                        }
                        &:nth-child(2){
                            width: 12vw;
                            font-size: 1rem;
                        }
                        &:nth-child(3){
                            display: none;    
                        }
                        &:nth-child(4){
                            display: none;
                        }
                        &:nth-child(5){
                            display: none;
                        }
                    }
                }
                .grade-content-body{
                    margin-bottom: 5rem;
                    .gradecontent-container{
                        .gradecontent-table{
                            width: 40vw;
                            font: 1rem;
                        }
                        .gradecontent-name{
                            width: 48vw;
                            font-size: 1rem;
                            margin-left: 5px;
                        }
                        .gradecontent-score{
                            width: 12vw;
                            font-size: 1rem;
                        }
                        .gradecontent-percent{
                            display: none;
                        }
                        .gradecontent-rank{
                            display: none;
                        }
                        .gradecontent-rating{
                            display: none;
                        }
                        .gradecontent-modify-remove{
                            display: none;
                        }
                    }
                    .grade-content-footer{
                        display: none;
                    }
                }
            }
        }
    }
}