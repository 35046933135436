.grade-content-dropdown-first{
    color: #a6a6a6;
    cursor: pointer;
    outline: none;
    font-size: 1rem;
    border: 1px solid #a6a6a6;
    min-height: 2rem;
    min-width: 5rem;
    margin-left: 3rem!important;
    .react-select__control{
        background: black;
        outline: none;
        border: none;
        min-height: 1.5rem;
        box-shadow: none;
        .react-select__value-container{
            border: none;
            .react-select__single-value{
                font-size: 1rem;
                color: white;
            }
        }
        .react-select__indicators{
            border: none;
            padding: 0;
            .react-select__indicator-separator{
                display: none;
            }
            .react-select__indicator{
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}
.newstudent-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .xl-download{
        position: fixed;
        bottom: 30px;
        right: 30px;
        border-radius: 20px;
        padding: 0.5rem 2rem;
        font-size: 1rem;
        cursor: pointer;
        font-weight: bold;
        color: white;
        text-decoration: none;
    }
    .newstudent-content-container{
        height: calc(100% - 8rem);
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .xl-upload{
            background: #28371b;
            &:hover{
                background: #151d0e;
            }
            color: white;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(90% + 1rem);
            font-size: 1.5rem;
            padding: 1rem;
            min-width: calc(600px - 2rem);
        }
        .newstudent-content-dropdown{
            color: white;
            border: none;
            width: 100%;
            min-width: calc(600px + 2rem);
            margin-bottom: 0.3rem;
            outline: none;
            .react-select__control{
                background: #262626;
                border: none;
                border-radius: 0;
                outline: none;
                box-shadow: none;
                padding: 0.9rem 0;
                .react-select__value-container{
                    border: none;
                    .react-select__placeholder{
                        font-size: 1rem;
                        color: #807f80;
                        font-size: 1.4rem;
                        margin-left: 0.5rem;
                    }
                    .react-select__single-value{
                        font-size: 1rem;
                        color: white;
                        font-size: 1.4rem;
                        margin-left: 0.5rem;
                    }
                }
            }
            .react-select__menu{
                background: #727171;
            }
        }
        .newstudent-content-title{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            color: white;
            padding: 0 1rem;
            font-size: 1.5rem;
            padding-bottom: 2rem;
        }
        .newstudent-content-input{
            background: #262626;
            border: none;
            font-size: 1.4rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
            margin-bottom: 0.3rem;
            &::placeholder{
                font-weight: 400;
            } 
        }
        .newstudent-content-group-add-container{
            width: 100%;
            min-width: 600px;
            display: flex;
            justify-content: flex-end;
            margin-top: 0.5rem;
            .newstudent-content-group-add{
                color: white;
                padding: 0.5rem 1.5rem;
                border: 1px solid #807f80;
                border-radius: 20px;
                margin: 0 1rem;
                text-decoration: none;
                &:hover{
                    color: #777676;
                }
            }
        }
        .newstudent-content-btn-container{
            width: calc(100% - 2rem);
            padding: 1rem;
            padding-top: 3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-width: 600px;
            .newstudent-content-btn{
                width: 45%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid #807f80;
                cursor: pointer;
                &:hover{
                    color: #777676;
                }
                &:nth-child(1){
                    margin-right: 0.5rem;
                }
                &:nth-child(2){
                    margin-left: 0.5rem;
                }
            }
            .newvid-content-btn{
                width: 100%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid #807f80;
                cursor: pointer;
            }
        }
    }
}

.react-select__option--is-focused{
    background: #262626!important;
}

#root > div > div > div.newstudent-content-container > div > div > div.react-select__indicators.css-1hb7zxy-IndicatorsContainer > div > svg{
    display: none;
}

@media screen and (max-width: 1024px) {
    .newstudent-container{
        overflow: hidden;
        .xl-download{
            display: none;
        }
        .newstudent-content-container{
            width: 100%;
            .newstudent-content-title{
                margin-left: 4rem;
            }
            .newstudent-content-input{
                width: 80%;
                min-width: 80%;
            }
            .newstudent-content-dropdown{
                width: calc(80% + 2rem);
                min-width: 80%;
            }
            .newstudent-content-group-add-container{
                .newstudent-content-group-add{
                    position: fixed;
                    bottom: 30px;
                    right: 30px;
                    margin: 0;
                }
            }
            .newstudent-content-btn-container{
                display: flex;
                flex-direction: column;
                .newstudent-content-btn{
                    font-size: 1.2rem;
                    &:nth-child(1){
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                    &:nth-child(2){
                        margin-left: 0;
                        margin-bottom: 0rem;
                    }
                }
            }
            .xl-upload{
                display: none;
            }
        }
    }
}

@media screen and (max-height: 500px) {

}