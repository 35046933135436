.vidcontent-container{
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    &:nth-child(1){
        padding-top: 1rem;
    }
    &:nth-child(2n){
        background-color: #171717;
    }
    .vidcontent-text{
        color: #a6a6a6;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        &:nth-child(1){
            margin-left: 7vw;
            width: 18vw;
        }
        &:nth-child(2){
            width: 16vw;
        }
        &:nth-child(3){
            width: 12vw;
        }
        &:nth-child(4){
            width: 12vw;
        }
        &:nth-child(5){
            width: 12vw;
        }
        &:nth-child(6){
            margin-left: 4vw;
            padding: 0.5rem 2vw;
            background: #2e3c20;
            color: white;
            cursor: pointer;
        }
    }
}

.matcontent-container{
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    &:nth-child(1){
        padding-top: 1rem;
    }
    &:nth-child(2n){
        background-color: #171717;
    }
    .matcontent-text{
        color: #a6a6a6;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        text-decoration: none;
        &:nth-child(1){
            margin-left: 10vw;
            width: 15vw;
        }
        &:nth-child(2){
            width: 15vw;
        }
        &:nth-child(3){
            width: 15vw;
        }
        &:nth-child(4){
            width: 15vw;
        }
        &:nth-child(5){
            margin-left: 4vw;
            padding: 0.5rem 2vw;
            background: #2e3c20;
            color: white;
            cursor: pointer;
            text-decoration: none;
        }
    }
}
.groupcontent-container{
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    padding: 0 10vw 0 15vw;
    width: 25vw;
    &:nth-child(1){
        padding-top: 1rem;
    }
    &:nth-child(2){
        padding-top: 1rem;
    }
    .groupcontent-text{
        color: #a6a6a6;
        font-size: 1.2rem;
        display: flex;
        justify-content: flex-start;
        width: 60%;
    }
    .groupcontent-btns{
        display: flex;
        flex-direction: row;
        color: #a6a6a6;
        .groupcontent-btn{
            font-size: 1.2rem;
            cursor: pointer;
            &:hover{
                color: #777676;
            }
        }
    }
}

.filecontent-container{
    width: 100%;
    height: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    &:nth-child(1){
        padding-top: 1rem;
    }
    &:nth-child(2n){
        background-color: #171717;
    }
    .filecontent-text{
        color: #a6a6a6;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        text-decoration: none;
        &:nth-child(1){
            margin-left: 14vw;
            width: 20vw;
        }
        &:nth-child(2){
            width: 15vw;
        }
        &:nth-child(3){
            width: 10vw;
        }
        &:nth-child(4){
            width: 20vw;
        }
        &:nth-child(5){
            padding: 0.5rem 2vw;
            background: #2e3c20;
            color: white;
            cursor: pointer;
        }
    }
    .filecontent-btns{
        display: flex;
        justify-content: center;
        width: 15vw;
        color: #a6a6a6;
        .filecontent-btn{
            &:nth-child(1){
                cursor: pointer;
            }
            &:nth-child(2){
                cursor: pointer;
                margin-right: 2vw;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .filecontent-container{
        .filecontent-text{
            &:nth-child(1){
                width: 60vw;
                font-size: 1rem;
            }
            &:nth-child(2){
                display: none;
            }
            &:nth-child(3){
                display: none;
            }
            &:nth-child(4){
                width: 40vw;
                font-size: 1rem;
            }
            &:nth-child(5){
                display: none;
            }
        }
    }
    .vidcontent-container{
        .vidcontent-text{
            &:nth-child(1){
                width: 60vw;
                font-size: 1rem;
            }
            &:nth-child(2){
                display: none;
            }
            &:nth-child(3){
                display: none;
            }
            &:nth-child(4){
                font-size: 1rem;
                width: 40vw;
            }
            &:nth-child(5){
                display: none;
            }
            &:nth-child(6){
                display: none;
            }
        }
    }
    .matcontent-container{
        .matcontent-text{
            &:nth-child(1){
                width: 60vw;
                font-size: 1rem;
            }
            &:nth-child(2){
                display: none;
            }
            &:nth-child(3){
                display: none;
            }
            &:nth-child(4){
                font-size: 1rem;
                width: 40vw;
            }
            &:nth-child(5){
                display: none;
            }
        }
    }
    .groupcontent-container{
        padding: 0;
        width: 100%;
        .groupcontent-text{
            width: 50%;
            display: flex;
            justify-content: center;
            margin-left: 10vw;
        }
        .groupcontent-btns{
            width: 40vw;
        }
    }
}
