.newtest-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .newtest-content-container{
        height: calc(100% - 8rem);
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .newtest-content-title{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            color: white;
            padding: 0 1rem;
            font-size: 1.5rem;
            padding-bottom: 2rem;
        }
        .newtest-content-dropdown{
            color: white;
            border: none;
            width: 100%;
            min-width: calc(600px + 2rem);
            margin-bottom: 0.3rem;
            outline: none;
            .react-select__control{
                background: #262626;
                border: none;
                border-radius: 0;
                outline: none;
                box-shadow: none;
                padding: 0.9rem 0;
                
                .react-select__value-container{
                    border: none;
                    .react-select__placeholder{
                        font-size: 1rem;
                        color: #807f80;
                        font-size: 1.4rem;
                        margin-left: 0.5rem;
                    }
                    .react-select__single-value{
                        color: white;
                        font-size: 1.4rem;
                        margin-left: 0.5rem;
                    }
                }
            }
            .react-select__menu{
                background: #727171;
            }
        }
        .newtest-content-input{
            background: #262626;
            border: none;
            font-size: 1.4rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
            margin-bottom: 0.3rem;
            &::placeholder{
                font-weight: 400;
            } 
        }
        .newtest-content-btn-container{
            width: calc(100% - 2rem);
            padding: 1rem;
            padding-top: 3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-width: 600px;
            .newtest-content-btn{
                width: 45%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid rgb(128, 127, 128);
                cursor: pointer;
                &:hover{
                    color: #777676;
                }
                &:nth-child(1){
                    margin-right: 0.5rem;
                }
                &:nth-child(2){
                    margin-left: 0.5rem;
                }
            }
        }
    }
}

.react-select__option--is-focused{
    background: #262626!important;
}


.react-select__dropdown-indicator{
    display: none!important;
}
.react-select__indicators{
    display: none!important;
}

@media screen and (max-width: 1024px) {
    .newtest-container{
        overflow-x: hidden;
        overflow-y: scroll;
        height: auto;
        .newtest-content-container{
            width: 100%;
            min-height: calc(100% - 48px);
            .newtest-content-title{
                margin-left: 4rem;
                margin-top: 2rem;
            }
            .newtest-content-input{
                width: 80%;
                min-width: 80%;
            }
            .newtest-content-dropdown{
                width: calc(80% + 2rem);
                min-width: 80%;
            }
            .newtest-content-btn-container{
                display: flex;
                flex-direction: column;
                padding-top: 1rem;
                .newtest-content-btn{
                    font-size: 1.2rem;
                    &:nth-child(1){
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                    &:nth-child(2){
                        margin-left: 0;
                    }
                }
            }
        }
    }
}