.test-container{
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    .test-content-container{
        width: 100%;
        min-height: calc(100% - 8rem);
        display: flex;
        flex-direction: column;
        .test-content-header-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 12rem;
            padding-top: 3rem;
            height: 4rem;
            color: white;
            width: calc(100% - 24rem);
            .test-content-header-left{
                width: 70%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .test-content-title{
                    font-size: 1.6rem;
                    min-width: 120px;
                }
                .mobile-separator{
                    display: flex;
                    flex-direction: row;
                    .test-content-dropdown-third{
                        color: #a6a6a6;
                        cursor: pointer;
                        outline: none;
                        font-size: 1rem;
                        border: 1px solid #a6a6a6;
                        min-height: 2rem;
                        min-width: 5.5rem;
                        margin-left: 0.5rem;
                        .react-select__control{
                            background: black;
                            outline: none;
                            border: none;
                            min-height: 1.5rem;
                            box-shadow: none;
                            .react-select__value-container{
                                border: none;
                                .react-select__single-value{
                                    font-size: 1rem;
                                    color: white;
                                }
                            }
                            .react-select__indicators{
                                border: none;
                                padding: 0;
                                .react-select__indicator-separator{
                                    display: none;
                                }
                                .react-select__indicator{
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                    .test-content-dropdown-second{
                        color: #a6a6a6;
                        cursor: pointer;
                        outline: none;
                        font-size: 1rem;
                        border: 1px solid #a6a6a6;
                        min-height: 2rem;
                        min-width: 8rem;
                        margin-left: 0.5rem;
                        .react-select__control{
                            background: black;
                            outline: none;
                            border: none;
                            min-height: 1.5rem;
                            box-shadow: none;
                            .react-select__value-container{
                                border: none;
                                .react-select__single-value{
                                    font-size: 1rem;
                                    color: white;
                                }
                            }
                            .react-select__indicators{
                                border: none;
                                padding: 0;
                                .react-select__indicator-separator{
                                    display: none;
                                }
                                .react-select__indicator{
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                    .test-content-dropdown-first{
                        color: #a6a6a6;
                        cursor: pointer;
                        outline: none;
                        font-size: 1rem;
                        border: 1px solid #a6a6a6;
                        min-height: 2rem;
                        min-width: 5.5rem;
                        margin-left: 2rem;
                        .react-select__control{
                            background: black;
                            outline: none;
                            border: none;
                            min-height: 1.5rem;
                            box-shadow: none;
                            .react-select__value-container{
                                border: none;
                                .react-select__single-value{
                                    font-size: 1rem;
                                    color: white;
                                }
                            }
                            .react-select__indicators{
                                border: none;
                                padding: 0;
                                .react-select__indicator-separator{
                                    display: none;
                                }
                                .react-select__indicator{
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                    .test-content-search-btn{
                        background: #404040;
                        color: #a8a8a8;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: calc(2rem - 1rem);
                        padding: 0.5rem 1.5rem;
                        margin-left: 0.5rem;
                        cursor: pointer;
                        min-width: 40px;
                        &:hover{
                            color: #777676;
                        }
                    }
                }
                
            }
            .test-content-header-right{
                width: 30%;
                display: flex;
                justify-content: flex-end;
                .test-register{
                    border: 1px solid #a6a6a6;
                    border-radius: 20px;
                    color: white;
                    font-size: 1.2rem;
                    padding: 0.2rem 1.5rem;
                    text-decoration: none;
                    cursor: pointer;
                    &:hover{
                        color: #777676;
                    }
                }
            }
        }
        .test-content-body-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .test-content-body-header{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                background: #262626;
                width: 100%;
                height: 3rem;
                .test-content-body-header-text{
                    color: #a6a6a6;
                    font-size: 1.2rem;
                    display: flex;
                    justify-content: center;
                    &:nth-child(1){
                        margin-left: 4vw;
                        width: 28vw;
                    }
                    &:nth-child(2){
                        width: 9vw;
                    }
                    &:nth-child(3){
                        width: 9vw;
                    }
                    &:nth-child(4){
                        width: 9vw;
                    }
                    &:nth-child(5){
                        width: 20vw;
                    }
                }
            }
            .test-content-body{
                width: 100%;
                min-height: calc(100vh - 18rem);
                .testcontent-container{
                    width: 100%;
                    height: 4rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 1.1rem;
                    &:nth-child(1){
                        padding-top: 1rem;
                    }
                    &:nth-child(2n){
                        background-color: #171717;
                    }
                    .testcontent-test-sort{
                        width: 28vw;
                        display: flex;
                        justify-content: center;
                        margin-left: 4vw;
                        cursor: pointer;
                        &:hover{
                            color: #777676;
                        }
                    }
                    .testcontent-average{
                        width: 9vw;
                        display: flex;
                        justify-content: center;
                    }
                    .testcontent-std-dev{
                        width: 9vw;
                        display: flex;
                        justify-content: center;
                    }
                    .testcontent-cand-num{
                        width: 9vw;
                        display: flex;
                        justify-content: center;
                    }
                    .testcontent-student{
                        width: 20vw;
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
                        color: #f6c243;
                        font-weight: 600;
                        font-size: 1.2rem;
                        &:hover{
                            color: #926a05;
                        }
                    }
                    .testcontent-modify-remove{
                        display: flex;
                        justify-content: center;
                        width: 20vw;
                        .testcontent-modify{
                            cursor: pointer;
                            &:hover{
                                color: #777676;
                            }
                        }
                        .testcontent-remove{
                            margin-right: 8vw;
                            cursor: pointer;
                            &:hover{
                                color: #777676;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1024px) {
    .test-container{
        .test-content-container{
            margin-top: 2.5rem;
            .test-content-header-container{
                padding: 0;
                width: 100%;
                height: 5.2rem;
                .test-content-header-left{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    .test-content-title{
                        font-size: 1.3rem;
                        display: flex;
                        justify-content: center;
                    }
                    .mobile-separator{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        margin-top: 1rem;
                        margin-bottom: 0.5rem;
                        .test-content-dropdown-first{
                            min-width: 4rem;
                            margin-left: 0;
                        }
                        .test-content-dropdown-second{
                            min-width: 5rem;
                        }
                        .test-content-dropdown-third{
                            min-width: 4rem;
                        }
                    }
                }
                .test-content-header-right{
                    width: 0;
                    .test-register{
                        position: absolute;
                        top: 60px;
                        right: 10px;
                        font-size: 1rem;
                    }
                }
            }
            .test-content-body-container{
                .test-content-body{
                    margin-bottom: 5rem;
                    min-height: calc(100% - 23rem);
                    .testcontent-container{
                        .testcontent-test-sort{
                            width: 60vw;
                            font-size: 1rem;
                        }
                        .testcontent-average{
                            display: none;
                        }   
                        .testcontent-std-dev{
                            display: none;
                        }
                        .testcontent-cand-num{
                            display: none;
                        }
                        .testcontent-student{
                            width: 40vw;
                            font-size: 1rem
                        }
                        .testcontent-modify-remove{
                            display: none;
                        }
                    }
                }
                .test-content-body-header{
                    .test-content-body-header-text{
                        &:nth-child(1){
                            width: 60vw;
                            font-size: 1rem;
                        }
                        &:nth-child(2){
                            display: none;
                        }
                        &:nth-child(3){
                            display: none;
                        }
                        &:nth-child(4){
                            display: none;
                        }
                        &:nth-child(5){
                            font-size: 1rem;
                            width: 40vw;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}