.newgrade-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .newgrade-content-container{
        height: calc(100% - 8rem);
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .newgrade-content-title{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            color: white;
            padding: 0 1rem;
            font-size: 1.5rem;
            padding-bottom: 2rem;
        }
        .newgrade-content-input{
            background: #262626;
            border: none;
            font-size: 1.4rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
            &::placeholder{
                font-weight: 400;
            } 
        }
        .newgrade-content-group-add-container{
            width: 100%;
            min-width: 600px;
            display: flex;
            justify-content: flex-end;
            margin-top: 0.5rem;
            .newgrade-content-group-add{
                color: white;
                padding: 0.5rem 1.5rem;
                border: 1px solid #807f80;
                border-radius: 20px;
                margin: 0 1rem;
                text-decoration: none;
            }
        }
        .newgrade-content-btn-container{
            width: calc(100% - 2rem);
            padding: 1rem;
            padding-top: 3rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            min-width: 600px;
            .newgrade-content-btn{
                width: 45%;
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 1.5rem;
                border: 1px solid #807f80;
                &:nth-child(1){
                    margin-right: 0.5rem;
                }
                &:nth-child(2){
                    margin-left: 0.5rem;
                }
            }
        }
    }
}