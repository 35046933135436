.signup-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .signup-logo{
        position: fixed;
        top: 3rem;
        left: 3rem;
        width: 160px;
    }
    .signup-input-container{
        display: flex;
        flex-direction: column;
        width: 50%;
        min-width: calc(600px + 2rem);
        .signup-input-text{
            color: white;
            font-size: 1.5rem;
            padding: 1rem;
        }
        .signup-input-username-container{
            width: 100%;
            background: #262626;
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 632px;
            .signup-input-username{
                background: #262626;
                border: none;
                font-size: 1.2rem;
                padding: 1rem;
                color: white;
                outline: none;
                width: 75%;
            }
            .signup-username-duplicate-container{
                width: 25%;
                display: flex;
                justify-content: flex-end;
                .signup-username-duplicate{
                    cursor: pointer;
                    padding: 1rem;
                }
            }
        }
        .signup-input-password{
            background: #262626;
            border: none;
            font-size: 1.2rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
        }
        .signup-input-checkpassword-container{
            width: 100%;
            background: #262626;
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 632px;
            .signup-input-checkpassword{
                background: #262626;
                border: none;
                font-size: 1.2rem;
                padding: 1rem;
                color: white;
                outline: none;
                width: 75%;
            }
            .signup-checkpassword-btn-container{
                width: 25%;
                display: flex;
                justify-content: flex-end;
                .signup-checkpassword-btn{
                    cursor: pointer;
                    padding: 1rem;
                }
            }
        }
        .signup-input-name{
            background: #262626;
            border: none;
            font-size: 1.2rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
        }
        .signup-input-phone{
            background: #262626;
            border: none;
            font-size: 1.2rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
        }
        .signup-input-email{
            background: #262626;
            border: none;
            font-size: 1.2rem;
            padding: 1rem;
            color: white;
            outline: none;
            width: calc(100% - 2rem);
            min-width: 600px;
        }
        .signup-terms{
            font-size: 1.5rem;
            padding: 1rem;
            padding-top: 2rem;
            color: white;
            padding-bottom: 0;
            width: calc(100% - 2rem);
            min-width: 600px;
        }
        .signup-terms-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 3rem;
            padding-top: 0.5rem;
            .signup-terms-container-left{
                display: flex;
                flex-direction: row;
                padding-bottom: 0.5rem;
                width: 50%;
                #signup-terms-allow{
                    display: none;
                }
                input[id="signup-terms-allow"] + label{
                    border: 2px solid #333333;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    margin-left: 2rem;
                    margin-right: 1rem;
                    color: white;
                    text-align: center;
                    font-size: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .signup-terms-text{
                    font-size: 1.1rem;
                    color: #807e80;
                }
            }
            .signup-terms-container-right{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                width: 50%;
                .show-content{
                    background: #262626;
                    color: #c7c7c7;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0.5rem 1.5rem;
                    float: right;
                    margin-right: 2rem;
                    cursor: pointer;
                    text-decoration: none;
                }
            }
        }
        .signup-private-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 3rem;
            .signup-private-container-left{
                display: flex;
                flex-direction: row;
                padding-bottom: 0.5rem;
                width: 50%;
                #signup-private-info{
                    display: none;
                }
                input[id="signup-private-info"] + label{
                    border: 2px solid #333333;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    margin-left: 2rem;
                    margin-right: 1rem;
                    color: white;
                    text-align: center;
                    font-size: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .signup-private-text{
                    font-size: 1.1rem;
                    color: #807e80;
                }
            }
            .signup-private-container-right{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                width: 50%;
                .show-content{
                    background: #262626;
                    color: #c7c7c7;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0.5rem 1.5rem;
                    float: right;
                    margin-right: 2rem;
                    cursor: pointer;
                    text-decoration: none;
                }
            }
        }
        .signup-btn{
            color: rgb(191, 191, 191);
            background: #262626;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            width: 100%;
            height: 3rem;
            cursor: pointer;
            margin-top: 2rem;
        }
        .modify-btn-container{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .modify-btn{
                color: rgb(191, 191, 191);
                background: #262626;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
                width: calc(50% - 0.5rem);
                height: 3rem;
                cursor: pointer;
                margin-top: 2rem;
                &:nth-child(1){
                    margin-right: 0.5rem;
                }
                &:nth-child(2){
                    margin-left: 0.5rem;
                }
            }
        }
    }
}

.modal-container{
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    opacity: 0.8;
    background: black;
    overflow: scroll;
    .modal-sticky{
        width: 60%;
        height: 80%;
        opacity: 1;
        z-index: 11;
    }
}

@media screen and (max-width: 1024px) {
    .signup-container{
        overflow-x: hidden;
        .signup-logo{
            display: none;
        }
        .signup-input-container{
            min-width: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            height: 100%;
            .signup-terms-container{
                .signup-terms-container-left{
                    width: 65%;
                    input[id="signup-terms-allow"] + label{
                        margin-left: 1rem;
                    }
                    .signup-terms-text{
                        font-size: 1rem;
                    }
                }
                .signup-terms-container-right{
                    width: 35%;
                    .show-content{
                        margin-right: 1rem;
                    }
                }
            }
            .signup-private-container{
                
                .signup-private-container-left{
                    width: 65%;
                    input[id="signup-private-info"] + label{
                        margin-left: 1rem;
                    }
                    .signup-private-text{
                        font-size: 1rem;
                    }
                }
                .signup-private-container-right{
                    width: 35%;
                    .show-content{
                        margin-right: 1rem;
                    }
                }
            }
            .signup-btn{
                width: 80%;
                margin-left: 10%;
                margin-bottom: 3rem;
            }
            .modify-btn-container{
                width: 90%;
                margin-left: 5%;
            }
            .signup-input-text{
                margin-left: 1rem;
            }
            .signup-input-username-container{
                width: calc(80% + 2rem);
                min-width: 80%;
                margin-left: calc(10% - 1rem);
                .signup-input-username{
                    width: 55%;
                }
                .signup-username-duplicate-container{
                    width: 45%;
                }
            }
            .signup-input-checkpassword-container{
                width: calc(80% + 2rem);
                min-width: 80%;
                margin-left: calc(10% - 1rem);
                .signup-input-checkpassword{
                    width: 55%;
                }
                .signup-checkpassword-btn-container{
                    width: 45%;
                }
            }
            .signup-input-password{
                width: calc(80% - 2rem);
                min-width: 80%;
                margin-left: calc(10% - 1rem);
            }
            .signup-input-name{
                width: calc(80% - 2rem);
                min-width: 80%;
                margin-left: calc(10% - 1rem);
            }
            .signup-input-phone{
                width: calc(80% - 2rem);
                min-width: 80%;
                margin-left: calc(10% - 1rem);
            }
            .signup-input-email{
                width: calc(80% - 2rem);
                min-width: 80%;
                margin-left: calc(10% - 1rem);
            }
        }
    }
}