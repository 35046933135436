#image-input{
    display: none;
}

.printpage-container{
    overflow: hidden;
    .printpage-sticky{
        display: flex;
        flex-direction: row;
        .mobile-container{
            display: none;
        }
        .printpage-btns{
            width: 48%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 4rem;
            height: calc(100vh - 8rem);
            .printpage-selector{
                display: flex;
                flex-direction: row;
                padding-bottom: 2rem;
                .printpage-btn{
                    background: #262626;
                    width: 250px;
                    height: 250px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.5rem;
                    cursor: pointer;
                    &:nth-child(1){
                        margin-right: 0.5rem;
                    }
                    &:nth-child(2){
                        margin-left: 0.5rem;
                    }
                }
            }
            
        }
        .container{
            overflow: hidden;
            min-width: 800px;
            min-height: 1131.2px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            .sticky-container{
                margin: 20px 20px;
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                .top-content{
                    width: 100%;
                    height: 45%;
                    .top-content-header{
                        width: 100%;
                        background: #265c5b;
                        height: 33%;
                        display: flex;
                        flex-direction: column;
                        .top-content-header-top{
                            width: 100%;
                            height: 50%;
                            display: flex;
                            align-items: flex-end;
                            .top-content-header-top-title{
                                margin-bottom: 18px;
                                margin-left: 45px;
                                word-spacing: 4px;
                                color: white;
                                font-size: 20px;
                                font-weight: lighter;
                                span{
                                    font-weight: normal;
                                }
                            }
                        }
                        .top-content-header-bottom{
                            width: 100%;
                            height: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            flex-direction: row;
                            .top-content-header-bottom-test-type{
                                font-size: 36px;
                                color: white;
                                font-weight: bold;
                                word-spacing: 4px;
                                margin-left: 45px;
                                margin-bottom: 27px;
                                width: 70%;
                            }
                            .top-content-header-bottom-name-grade-group{
                                width: 30%;
                                color: white;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                .ngg{
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    margin-right: 45px;
                                    margin-bottom: 18px;
                                    .name{
                                        font-size: 27px;
                                        font-weight: bold;
                                        margin-bottom: 9px;
                                    }
                                    .grade{
                                        font-size: 12px;
                                    }
                                    .group{
                                        font-size: 12px;
                                    }
                                }
                                
                            }
                        }
                    }
                    .top-content-body{
                        height: 50%;
                        width: 100%;
                        margin-left: 15px;
                        .top-content-body-first{
                            margin-top: 18px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            height: 20%;
                            .top-content-body-title{
                                color: black;
                                font-size: 15px;
                                font-weight: bold;
                                width: 18%;
                                border: none;
                                padding: 9px 0;
                                border-right: 1px solid #d6c6b5;
                            }
                            .top-content-body-text{
                                color: 		#696969;
                                font-weight: bold;
                                width: calc(82% - 18px);
                                margin-left: 18px;
                                font-size: 11px;
                            }
                        }
                        .top-content-body-second{
                            margin-top: 9px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            height: 20%;
                            .top-content-body-title{
                                color: black;
                                font-size: 14px;
                                font-weight: bold;
                                width: 18%;
                                border: none;
                                padding: 9px 0;
                                border-right: 1px solid #d6c6b5;
                            }
                            .top-content-body-text{
                                color: 		#696969;
                                font-weight: bold;
                                width: calc(82% - 18px);
                                margin-left: 18px;
                                font-size: 11px;
                            }
                        }
                        .table{
                            height: 45%;
                            width: 77%;
                            margin-left: 18%;
                            border: 1px solid #a6a6a6;
                            border-right: none;
                            border-left: none;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            .table-1{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                height: calc(100% - 2px);
                                width: calc(100% / 5);
                                .title{
                                    height: calc(100% / 6);
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: #f2f2f2;
                                    color: #303030;
                                    font-size: 12px;
                                }
                                .content{
                                    font-size: 12px;
                                    color: 		#696969;
                                    font-weight: bold;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: calc(100% / 6);
                                }
                            }
                            .table-2{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                height: calc(100% - 2px);
                                width: calc(100% / 5);
                                .title{
                                    height: calc(100% / 6);
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: #f2f2f2;
                                    color: #303030;
                                    font-size: 12px;
                                }
                                .content{
                                    font-size: 12px;
                                    color: 		#696969;
                                    font-weight: bold;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: calc(100% / 6);
                                }
                            }
                            .table-3{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                height: calc(100% - 2px);
                                width: calc(100% / 5);
                                .title{
                                    height: calc(100% / 6);
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: #f2f2f2;
                                    color: #303030;
                                    font-size: 12px;
                                }
                                .content{
                                    font-size: 12px;
                                    color: 		#696969;
                                    font-weight: bold;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: calc(100% / 6);
        
                                }
                            }
                            .table-4{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                height: calc(100% - 2px);
                                width: calc(100% / 5);
                                .title{
                                    height: calc(100% / 6);
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: #f2f2f2;
                                    color: #303030;
                                    font-size: 12px;
                                }
                                .content{
                                    font-size: 12px;
                                    color: 		#696969;
                                    font-weight: bold;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: calc(100% / 6);
                                }
                            }
                            .table-5{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                height: calc(100% - 2px);
                                width: calc(100% / 5);
                                .title{
                                    height: calc(100% / 6);
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: #f2f2f2;
                                    color: #303030;
                                    font-size: 12px;
                                }
                                .content-end{
                                    font-size: 11px;
                                    color: 		#696969;
                                    font-weight: bold;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: calc((100% / 6) * 5);
                                }
                            }
                        }
                    }
                }
                .bottom-content{
                    height: 55%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    .bottom-content-header{
                        width: 100%;
                        height: 24%;
                        background: #b02318;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        .bottom-content-header-left{
                            width: 50%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            color: white;
                            .header-left-top{
                                display: flex;
                                flex-direction: row;
                                height: 50%;
                                align-items: flex-end;
                                justify-content: center;
                                margin-bottom: 2px;
                                .header-left-top-school{
                                    padding: 9px;
                                    border-right: 0.5px solid white;
                                    font-size: 18px;
                                    padding-right: 13.5px;
                                    word-spacing: 2px;
                                    max-width: 220px;
                                }
                                .header-left-top-subject{
                                    font-size: 27px;
                                    padding: 9px;
                                    font-weight: bold;
                                    padding-bottom: 2.7px;
                                    padding-left: 13.5px;
                                    word-spacing: 2px;
                                }
                            }
                            .header-left-bottom{
                                font-size: 14.4px;
                                height: 50%;
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;
                                word-spacing: 1.8px;
                            }
                        }
                        .bottom-content-header-middle{
                            width: 20%;
                            height: 100%;
                            color: #fffe54;
                            font-size: 18px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 70px;
                            span{
                                font-size: 80px;
                                font-weight: bold;
                                margin: 0 7px;
                            }
                            .middle-text{
                                height: 45%;
                                display: flex;
                                align-items: flex-end;
                            }
                        }
                        .bottom-content-header-right{
                            width: 35%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            color: white;
                            margin-right: 20px;
                            .header-right-top{
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: flex-end;
                                width: 100%;
                                height: 50%;
                                .header-right-top-myscore{
                                    font-size: 13.5px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: 1px solid white;
                                    width: 40%;
                                    height: 70%;
                                    font-weight: bold;
                                }
                                .header-right-top-score{
                                    font-size: 27px;
                                    font-weight: bold;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: 1px solid white;
                                    border-left: none;
                                    width: 40%;
                                    height: 70%;
                                }
                            }
                            .header-right-bottom{
                                height: 50%;
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: flex-start;
                                justify-content: center;
                                font-size: 11px;
                                font-weight: 600;
                                .header-right-bottom-z{
                                    width: 40%;
                                    height: 70%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    .header-right-bottom-z-top{
                                        margin-top: 11.7px;
                                    }
                                    .header-right-bottom-z-bottom{
                                        margin-top: 13.5px;
                                    }
                                }
                                .header-right-bottom-prob{
                                    width: 40%;
                                    height: 70%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    .header-right-bottom-prob-top{
                                        margin-top: 11.7px;
                                    }
                                    .header-right-bottom-prob-bottom{
                                        margin-top: 13.5px;
                                    }
                                }
                            }
                        }
                    }
                    .bottom-content-header-sec{
                        background: #d9d9d9;
                        width: 100%;
                        height: 18%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 0.5%;
                        .bottom-content-header-sec-rank{
                            display: flex;
                            flex-direction: column;
                            color: #eb3323;
                            align-items: center;
                            justify-content: flex-start;
                            width: 22%;
                            height: 70%;
                            .red-title{
                                font-size: 13.5px;
                            }
                            .red-content{
                                font-weight: bold;
                                font-size: 24.3px;
                                padding-top: 22.5px;
                            }
                        }
                        .bottom-content-header-sec-percent{
                            display: flex;
                            flex-direction: column;
                            color: #eb3323;
                            align-items: center;
                            width: 22%;
                            justify-content: flex-start;
                            height: 70%;
                            .red-title{
                                font-size: 13.5px;
                            }
                            .red-content{
                                font-weight: bold;
                                font-size: 24.3px;
                                padding-top: 22.5px;
                            }
                        }
                        .bottom-content-header-sec-cand-num{
                            display: flex;
                            flex-direction: column;
                            color: #59595d;
                            align-items: center;
                            width: 18%;
                            justify-content: flex-start;
                            height: 70%;
                            .gray-title{
                                font-size: 11.7px;
                            }
                            .gray-content{
                                font-weight: 400;
                                font-size: 20px;
                                padding-top: 22.5px;
                            }
                        }
                        .bottom-content-header-sec-average{
                            display: flex;
                            flex-direction: column;
                            color: #59595d;
                            align-items: center;
                            width: 18%;
                            justify-content: flex-start;
                            height: 70%;
                            .gray-title{
                                font-size: 11.7px;
                            }
                            .gray-content{
                                font-weight: 400;
                                font-size: 20px;
                                padding-top: 22.5px;
                            }
                        }
                        .bottom-content-header-sec-std-dev{
                            display: flex;
                            flex-direction: column;
                            color: #59595d;
                            align-items: center;
                            width: 18%;
                            justify-content: flex-start;
                            height: 70%;
                            .gray-title{
                                font-size: 11.7px;
                            }
                            .gray-content{
                                font-weight: 400;
                                font-size: 20px;
                                padding-top: 22.5px;
                            }
                        }
                    }
                    .bar-container{
                        width: 100%;
                        height: 50%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        .chart-1{
                            width: 80%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .chart-2{
                            width: 80%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .logo-container{
                        height: 7.5%;
                    }
                    .print-logo-container{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .logo-sticky-container{
                            width: 400px;
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}




#reactgooglegraph-3{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1vw;
}
#reactgooglegraph-4{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1vw;
    color: rgb(149, 180, 223);
}

div > div > svg > g:nth-child(2) > rect:nth-child(2){
    display: none;
}

div > div > svg > g:nth-child(2) > text{
    display: none;
}
#reactgooglegraph-4 > div > div:nth-child(1) > div > svg > g:nth-child(3){
    display: none;
}
#reactgooglegraph-3 > div > div:nth-child(1) > div > svg > g:nth-child(3) > g{
    display: none;
}


@media screen and (max-width: 1024px) {
    .header-container{
        margin-bottom: 0;
        

    }
    .printpage-container{
        overflow-y: scroll;
        .printpage-sticky{
            .printpage-btns{
                display: none;
            }
            .container{
                display: none;
            }
            .mobile-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                font-weight: 500;
                background: rgb(217, 217, 217);
                min-height: calc(100vh - 48px);
                .mobile-sticky{
                    width: 95%;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background: black;
                    min-height: calc(100vh - 48px);
                    padding-top: 2rem;
                    .mobile-top{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding-bottom: 1.2rem;
                        background: black;
                        .mobile-top-1{
                            font-size: 1.2rem;
                        }
                        .mobile-top-2{
                            font-size: 1.8rem;
                            font-weight: bold;
                            padding: .8rem 0;
                        }
                        .mobile-top-3{
                            font-size: 1.1rem;
                            word-spacing: 3px;
                        }
                    }
                    .mobile-mid{
                        width: 100vw;
                        background: rgb(130, 23, 15);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 2rem 0;
                        z-index: 999;
                        .mobile-mid-top{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            margin-bottom: 2rem;
                            .mobile-mid-top-left{
                                width: 65%;
                                display: flex;
                                flex-direction: column;
                                border-right: 1px solid white;
                                align-items: center;
                                .mobile-mid-top-left-top{
                                    font-size: 1.2rem;
                                }
                                .mobile-mid-top-left-bot{

                                }
                            }
                            .mobile-mid-top-right{
                                font-size: 1.8rem;
                                font-weight: bolder;
                                width: 25%;
                                text-align: center;
                            }
                        }
                        .mobile-mid-bot{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            width: 100%;
                            font-weight: 500;
                            
                            .mobile-mid-bot-1{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 40%;
                                .mobile-mid-bot-1-top{
                                    font-size: 1.2rem;
                                    margin-bottom: .5rem;
                                }
                                .mobile-mid-bot-1-bot{
                                    font-size: 4rem;
                                    font-weight: bolder;
                                }
                            }
                            .mobile-mid-bot-2{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 40%;
                                color: rgb(249, 217, 120);
                                .mobile-mid-bot-2-top{
                                    font-size: 1.2rem;
                                    margin-bottom: .5rem;
                                }
                                .mobile-mid-bot-2-bot{
                                    font-size: 4rem;
                                    font-weight: bolder;
                                }
                            }
                        }
                    }
                    .mobile-bot{
                        background: rgb(27, 71, 70);
                        width: 100vw;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-weight: 500;
                        margin-top: .5rem;
                        padding: 2rem 0;
                        z-index: 999;
                        .mobile-bot-top{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            color: rgb(249, 217, 120);
                            width: 100%;
                            margin-bottom: 2rem;
                            .mobile-bot-top-1{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 40%;
                                .mobile-bot-top-1-top{
                                    font-size: 1.2rem;
                                    font-weight: 500;
                                    margin-bottom: .5rem;
                                }
                                .mobile-bot-top-1-bot{
                                    font-weight: 800;
                                    font-size: 2rem;
                                }
                            }
                            .mobile-bot-top-2{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 40%;
                                .mobile-bot-top-2-top{
                                    font-size: 1.2rem;
                                    font-weight: 500;
                                    margin-bottom: .5rem;
                                }
                                .mobile-bot-top-2-bot{
                                    font-weight: 800;
                                    font-size: 2rem;
                                }
                            }
                        }
                        .mobile-bot-bot{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            width: 100%;
                            .mobile-bot-bot-1{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 25%;
                                .mobile-bot-bot-1-top{
                                    font-size: 1rem;
                                    font-weight: 500;
                                    margin-bottom: .5rem;
                                }
                                .mobile-bot-bot-1-bot{
                                    font-size: 1.5rem;
                                    font-weight: 800;
                                }
                            }
                            .mobile-bot-bot-2{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 25%;
                                .mobile-bot-bot-2-top{
                                    font-size: 1rem;
                                    font-weight: 500;
                                    margin-bottom: .5rem;
                                }
                                .mobile-bot-bot-2-bot{
                                    font-size: 1.5rem;
                                    font-weight: 800;
                                }
                            }
                            .mobile-bot-bot-3{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                width: 25%;
                                .mobile-bot-bot-3-top{
                                    font-size: 1rem;
                                    font-weight: 500;
                                    margin-bottom: .5rem;
                                }
                                .mobile-bot-bot-3-bot{
                                    font-size: 1.5rem;
                                    font-weight: 800;
                                }
                            }
                        }
                    }
                    .mobile-footer{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        margin: 1rem 0;
                        padding: 1rem 0;
                        background: #424242;
                        .footer-container{
                            height: 30px;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}